@import '../../styles/customMediaQueries.css';

.root {

}

.pageRoot {

}

.payoutDetailsWarning {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.longWord {
  word-break: break-word;
  hyphens: auto;
}
