@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.avatar {
  margin: 0 0 12px;
  width: 88px;
  height: 88px;
}

.formTitle {
  padding: 0;
  margin: 0 0 12px;
}

.formDescription {
  font-size: 14px;
  line-height: 20px;
  font-weight: var(--fontWeightRegular);
  padding: 0;
  margin: 0 0 22px;
}

.bookingBreakdownTitle {
  /* Font */
  letter-spacing: -0.5px;
  font-weight: var(--fontWeightBold);
  font-size: 18px;
  line-height: 1.15;
  text-transform: none;

  /* Layout spacing */
  margin: 5px 24px 10px 0;

  @media (--viewportLarge) {
    padding: 0;
    margin: 0 0 22px 0;
    font-size: 22px;
  }
}

.totalDivider {
  /* dimensions */
  width: 100%;
  height: 1px;
  margin: 12px 0 5px 0;

  border: none;
  background-color: var(--colorGrey100);

  @media (--viewportMedium) {
    margin: 7px 0 0px 0;
  }
}

.bookingDates {
  flex-shrink: 0;
  background-color: var(--colorWhite);
  padding: 0;
  margin-bottom: 10px;
  border-radius: 12px;

  @media (--viewportLarge) {
    padding: 20px 0 20px;
  }

  /* Ensure that calendar dropdown gets some stacking context relative to other form items below */
  z-index: 1;

  & :global(.DateInput_input) {
    padding-top: 5px;
    padding-bottom: 5px;
    line-height: 24px;
    background-position: 8px 8px;
    border-radius: 20px;
  }

  & :global(.SingleDatePickerInput) {
    flex-direction: column;
  }

  /* & :global(.SingleDatePickerInput .DateInput) {
    visibility: hidden;
    height: 0;
    pointer-events: none;
  } */

  & :global(.SingleDatePickerInput .SingleDatePicker_picker) {
    position: static;
    border-radius: 12px;
    box-shadow: none;
  }

  & :global(input.DateInput_input),
  & :global(input.DateInput_input:hover),
  & :global(input.DateInput_input:focus) {
    height: 36px;
    border-radius: 20px;
    padding: 0px 0 0px 32px;
    font-weight: var(--fontWeightRegular);
    background-position: 10px 8px;
  }
}

.bookingDatesProfile {
  padding: 0;
}

.bookingDatesFreeInquiry {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  margin: 0;
  background-color: transparent;
  
  @media (--viewportMedium) {
    flex-direction: row;
  }

  & :global(input.DateInput_input),
  & :global(input.DateInput_input:hover),
  & :global(input.DateInput_input:focus) {
    width: 100%;
    height: 36px;
    border-radius: 20px;
    padding: 0 16px;
    font-weight: var(--fontWeightRegular);
    background-position: calc(100% - 16px) 8px;
    font-size: 14px;

    &::placeholder {
      color: var(--colorDefaultGray);
    }

    @media (--viewportMedium) {
      max-width: 200px;
    }
  }
}

.labelFreeInquiry {
  padding: 0;
  margin: 0 0 12px;
  font-size: 16px;
  line-height: 22px;
  font-weight: var(--fontWeightSemiBold);
}

.priceBreakdownContainer {
  padding: 0;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 40px;
  }
}

.receipt {
  flex-shrink: 0;
  margin: 0 0 24px 0;
}

.error {
  color: var(--colorFail);
  margin: 0 24px;
  display: inline-block;
}

.sideBarError {
  composes: h4 from global;
  color: var(--colorFail);
  margin: 0 24px 12px 24px;

  @media (--viewportMedium) {
    margin: 24px 0 12px 0;
  }
}

.finePrint {
  composes: marketplaceTinyFontStyles from global;
  text-align: center;
  margin-top: 8px;
}

.buttonWrapper {
  margin-top: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;

  & .button {
    max-width: 120px;
    width: 100%;
    flex-shrink: 0;
    min-height: 38px;
  }
  
  & .submitButton {
    margin-top: 0;
  }
  
  & .cancelButton {
    background-color: var(--colorWhite);
  }
}

.submitButton {
  padding: 0 24px;
  margin-top: 24px;
  composes: buttonPrimary from global;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 72px;
  }

  & button {
    border-radius: 20px;
    min-height: 38px;
  }
}

.useVoucher {
  & label span {
    font-size: 14px;
    color: var(--colorBlack);
    font-weight: var(--fontWeightRegular);
  }
}

.applyButton {
  min-height: 36px;
  max-width: 70px;
  margin-left: 10px;
  border-radius: 20px;

  font-size: 14px;
  font-weight: var(--fontWeightLight);

  &:not(:disabled) {
    background-color: var(--marketplaceGreen);
    color: var(--colorWhite);
  }
}

.input {
  width: 100%;
  /* border: 1px solid #e1e4eb; */
  /* border-radius: 20px; */
  padding: 2px 16px;
  line-height: 30px;
  /* border-color: var(--marketplaceGreen); */
  /* border: none !important; */
  
  &::placeholder {
    color: var(--marketplaceSecondaryColor);
  }
  
  @media (--viewportMedium) {
    max-width: 200px;
  }
}

.wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.inputWrapper {
  position: relative;

  & svg {
    position: absolute;
    right: 32px;
    top: 8px;
  }
}

.voucherErrorBox {
  margin-top: 20px;
  background-color: #ffdedf;
  padding: 12px;
  border-radius: 4px;
  border: 0.5px solid #335a50;
}

.voucherErrorBoxText {
  font-weight: 700;
  line-height: 20px;
  font-size: 14px;
  font-style: normal;
}

.phoneNumber {
  width: 100%;
  margin: 0 0 28px;

  @media (--viewportMedium) {
    max-width: 200px;
  }
}

.phoneNumberField {

  & input {
    background-color: var(--colorWhite);
  }
}

.notAvailable {
  padding-top: 10px;

  & .submitButton {
    margin-top: 262px;
  }
}

.notAvailableInfo {
  padding: 12px;
  border-radius: 4px;
  border: 1px solid var(--marketplaceGreen);
  background-color: #EAF2F1;
}

.notAvailableInfoTitle {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 0 8px;
  font-size: 14px;
  line-height: 20px;
  font-weight: var(--fontWeightBold);

  & svg {
    flex-shrink: 0;
    margin-right: 8px;
  }
}

.notAvailableInfoDescription {
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 20px;
  font-weight: var(--fontWeightRegular);

  & a {
    text-decoration: underline;
    color: var(--marketplaceSecondaryColor);
  }
}
