.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--borderRadiusMedium);
  cursor: pointer;
}

.imageRounded {
  composes: image;
  border-radius: 50%;
}

.file {
  text-decoration: underline;
  color: var(--linksColor);
  font-weight: var(--fontWeightSemiBold);
  cursor: pointer;
  word-break: break-word;

  & svg {
    margin-right: 10px;
  }
}

.filePreview {
  display: flex;
  align-items: center;

  max-width: 429px;
  height: 304px;
  margin: 0 auto;

  & svg {
    width: 100%;
    /*height: 100%;*/
    max-width: 429px;
    height: 304px;
  }
}

.imageHolder {
  height: 100%;
  width: 100%;
}
