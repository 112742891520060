@import '../../../styles/customMediaQueries.css';

.mainColumnForProductLayout {
  padding: 0 24px;
  margin: 0 auto;
  @media (--viewportMedium) {
    max-width: 1302px;
    flex-grow: 1;

  }
}

.orderColumnForProductLayout {
  margin-bottom: 0;

  @media (--viewportLarge) {
    max-width: 473px;
    flex-grow: 1;
  }
}

.contentWrapperForProductLayout {
  margin: 0 0 56px 0;

  @media (--viewportMedium) {
    width: 100%;
    margin: 0 auto 56px auto;
  }
}

.mainColumnForHeroLayout {
  flex-basis: 100%;
  margin-bottom: 23px;

  @media (--viewportMedium) {
    margin-top: 50px;
    margin-bottom: 51px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-top: 80px;
    margin-bottom: 0px;
    /* contentContainer's full width - (orderColumn + two paddings + margin + border) */
    max-width: calc(100% - 433px);
    flex-basis: calc(100% - 433px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.orderColumnForHeroLayout {

  @media (--viewportLarge) {
    display: block;
    margin-top: 80px;
    margin-left: 60px;
    border-left: 1px solid var(--colorGrey100);
    padding-left: 60px;
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.sectionHeroWraapper {
  background-color: var(--backgroundGray);
}

.sectionHeroContainer {
  padding: 40px 24px;
  margin: 0 auto;

  @media (--viewportMedium) {
    max-width: 1302px;
    flex-grow: 1;
  }
  
  @media (--viewportLarge) {
    padding: 44px 24px 44px;
  }
}

.sectionHero {
  margin: 0 auto;
  width: 100%;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  
  @media (--viewportLarge) {
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
  }
}

.actionBar {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  padding: 0 24px;
  color: var(--colorGrey100);
  background-color: var(--colorGrey700);
  z-index: 1; /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  border-radius: var(--borderRadius);

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.actionBarForProductLayout {
  padding: 0;
  margin: 24px auto;
  width: 100%;
}

.ownListingText {
  margin: 14px 24px 11px 24px;
  padding: 0;

  @media (--viewportMedium) {
    margin: 24px 12px 24px 24px;
  }
}

.ownListingTextPendingApproval {
  color: var(--colorAttention);
}

.closedListingText {
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.addPayoutDetails,
.editListingLink {
  flex-shrink: 0;
  margin: 14px 24px 11px 24px;
  color: var(--marketplaceColor);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--marketplaceColorDark);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 24px 24px 24px 12px;
  }
}

.missingPayoutDetailsText {
  color: var(--colorAttention);
}

.payoutDetailsWarning {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.editIcon {
  margin: -6px 7px 0 0;
}

.rootForImage {
  width: 100%;
  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    object-fit: cover;
    border-radius: var(--borderRadius);

    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.viewPhotos {
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 8px 13px 6px 13px;

  /* Colors */
  background-color: var(--colorWhite);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--colorWhite);
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.orderPanel {

}

.productOrderPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 24px;
  }
}

.orderPanelTitle {
  /* Font */
  font-weight: var(--fontWeightBold);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -31px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Flexbox would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    position: absolute;
    top: -49px;
    margin-left: 0;
    margin-top: 0;
  }
}

.avatarMobile {
  display: flex;

  @media (--viewportMedium) {
    display: none; /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none; /* Hide the large avatar from the smaller screens */

  @media (--viewportMedium) {
    display: flex;
  }
}

.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 1px;
  }
}

.desktopPerUnit {
  /* Font */
  composes: h5 from global;
  color: var(--colorGrey700);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  margin-bottom: 33px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}

.title {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--colorGrey700);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 2px;
  }
}

.author {
  width: 100%;
  composes: h5 from global;

  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--colorGrey700);
  }
}

.authorNameLink {
  composes: textSmall from global;
  color: var(--linksColor);
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    line-height: 16px;
  }
}

.sectionText {
  padding: 0;

}

.sectionTextFirst {
  margin-bottom: 40px;
  
  @media (--viewportLarge) {
    margin-bottom: 64px;
  }

  & .sectionHeading,
  & .sectionHeadingWithExtraMargin {

    @media (--viewportLarge) {
      margin-top: 30px;
      font-size: 38px;
    }
  }
}

.sectionHeading,
.sectionHeadingWithExtraMargin {
  /* Font for sections titles */
  padding: 0;
  margin: 40px 0 20px;
  font-size: 28px;
  line-height: 1.25;
  font-weight: var(--fontWeightBold);

  @media (--viewportLarge) {
    margin: 60px 0 20px;
  }
}

.sectionHeadingWithExtraMargin {
  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
}

.sectionHeadingSmall {
  font-size: 22px;
}

.sectionSubHeading {
  font-size: 14px;
  line-height: 20px;
  font-weight: var(--fontWeightRegular);
}

.sectionInfoTip {
  display: flex;
  align-items: flex-start;
  padding: 8px 16px;
  max-width: 492px;
  border: 1px solid var(--marketplaceGreen);
  border-radius: var(--borderRadiusMedium);
  background-color: var(--colorWhite);

  font-size: 14px;
  line-height: 20px;
  font-weight: var(--fontWeightRegular);

  & svg {
    margin: 2px 5px 0 0;
    flex-shrink: 0;
  }
}

.text {
  font-weight: var(--fontWeightRegular);
  padding: 1px 0 5px 0;
  margin: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    max-width: 980px;
    padding: 3px 0 5px 0;
    margin: 0;
  }
}

.ingress {
  composes: p from global;
  margin: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.sectionDetails,
.sectionMultiEnum {
  padding: 0 24px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 40px;
  }
}

.sectionRow {
  margin: 0 0 44px;
  
  @media (--viewportLarge) {
    margin: 0 -11px 44px;
    display: flex;
    justify-content: flex-start;
  }

  & .section {
    margin: 0 0 20px;
    padding: 22px;
    border: 1px solid #859C96;
    background-color: #F6F9F9;
    border-radius: 8px;
    
    @media (--viewportLarge) {
      width: calc(25% - 11px);
      margin: 0 11px 0;
    }
  }

  & .sectionHeading {
    margin: 0;
  }
  
  & .sectionSubHeading {
    
    & svg {
        margin: 0 5px 5px 0;
    }
  }
}

.sectionMap {
  padding: 0;
}

.mapAddress {
  display: block;
  padding: 0;
  margin: 44px 0 12px;
  font-size: 16px;
  line-height: 1.2;
  letter-spacing: -0.5px;
  color: var(--linksColor);
  font-weight: var(--fontWeightRegular);

  & svg {
    margin-right: 5px;
    width: 18px;
    height: 18px;
  }

  & path {
    fill: var(--linksColor);
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: 332px;
  width: 100%;
  max-width: calc(100vw - 48px);

  /* Static map: dimensions are 640px */
  max-height: 640px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportLarge) {
    margin-bottom: 7px;
  }
}

.details {
  padding-bottom: 1px;
  margin: 0;
}

.detailsRow {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  padding: 1px 0 4px 0;
  line-height: 36px;
  list-style: none;
  font-weight: var(--fontWeightRegular);

  &:not(:last-child) {
    border-bottom: 1px solid var(--colorGrey100);
  }

  @media (--viewportMedium) {
    padding: 5px 0 2px 0;
    line-height: 32px;
  }
}

.detailLabel {
  font-weight: var(--fontWeightSemiBold);
  letter-spacing: -0.09px;
}

.inquiryModalContainer {
  composes: marketplaceModalRootStyles from global;

  & > button {
    display: none;
  }
}

.inquiryModalContent {
  composes: marketplaceModalBaseStyles from global;
  border-radius: 16px;
  padding: 44px 24px;
  flex-basis: 612px;
  background-color: var(--backgroundGray);
  
  @media (--viewportMedium) {
    padding: 44px;
  }
}

.inquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.inquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--colorWhite);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.longWord {
  word-break: break-word;
  hyphens: auto;
}

.mobileHeading {
  margin: 0 24px 24px 24px;

  @media (--viewportMedium) {
    margin: 0 0 24px 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.productDesktopHeading {
  display: none;
  @media (--viewportLarge) {
    display: block;
  }
}

.productSideContent {
}

.productBookingPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 24px;

    /* The calendar needs 312px */
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.collaborationInfo {
  padding: 0;
  margin: 0;

  & .collaborationIcon {
    fill: none;
    margin-right: 5px;
  }
}

.holisticHealthFocusList {
  margin: 32px 0 0;
}

.holisticHealthFocusListItem {
  display: inline-flex;
  align-items: center;
  margin: 0 15px 15px 0;
  padding: 0 14px;
  border-radius: 20px;
  font-size: 12px;
  line-height: 34px;
  background-color: var(--backgroundGray);
  font-weight: var(--fontWeightRegular);
  font-family: var(--buttonFontFamily);

  & svg {
    margin-right: 5px;
  }
}

.sectionItemsList {
  margin: 22px 0 0;
}

.sectionItemsListItem {
  display: flex;
  align-items: center;
  padding-top: 0;
  margin: 0 0 12px 0;
  font-size: 16px;
  line-height: 22px;
  font-weight: var(--fontWeightRegular);
  
  & svg {
    margin-right: 5px;
  }
}


.productGallery {
  padding: 0;
  margin: 0;
  width: 100%;
  max-width: calc(100vw - 48px);
  /* height: 320px;

  @media (--viewportMedium) {
    height: 425px;
  } */

  @media (--viewportLarge) {
    width: calc(100% - 329px);
  }
  
  & :global(.alice-carousel),
  & :global(.alice-carousel) > div:first-of-type,
  & :global(.alice-carousel__wrapper) {
    position: relative;
    height: 100%;
  }

  & :global(.alice-carousel) > div:first-of-type {
    z-index: 1;
  }

  & :global(.alice-carousel__prev-btn),
  & :global(.alice-carousel__next-btn) {
    position: absolute;
    width: auto;
    bottom: 50%;
    transform: translateY(50%);
    padding: 20px;
    cursor: pointer;
    z-index: 9;

    & button {
      border: none;
      padding: 0;
      cursor: pointer;
    }
  }

  & :global(.alice-carousel__prev-btn) {
    left: 0;
  }

  & :global(.alice-carousel__next-btn) {
    right: 0;
  }
}

.productGalleryMain {
  @media (--viewportLarge) {
    width: calc(100% - 505px);
  }
}

.productGalleryItem {
  width: 100%;
  height: 100%;
}

.productGalleryVideo {

   & iframe {
    position: relative;
    max-width: 100vw;
    height: 320px;
    z-index: 1;

    @media (--viewportMedium) {
      height: 425px;
    }
   }
}

.productGalleryImage {
  position: relative;

  & > div {
    max-height: 100%;
    position: relative;
  }

  & img {
    width: 100%;
    height: 320px;
    object-fit: cover;

    @media (--viewportMedium) {
      height: 425px;
    }
  }
}

.sectionAuthor {
  border: 1px solid var(--marketplaceGreen);
  border-radius: 12px;
  padding: 40px;
  margin-top: 40px;

  & .sectionHeadingBig {
    margin: 0 0 40px;
    font-size: 28px;
    
    @media (--viewportMedium) {
      font-size: 38px;
    }
  }
}

.sectionAuthorContent {
  
  @media (--viewportLarge) {
    display: flex;
    justify-content: space-between;
  }

  & .productGallery {
    max-height: 425px;
    max-width: calc(100vw - 128px);
  }
}

.techniqueTypesList {
  margin: 40px 0 0;
  
  @media (--viewportMedium) {
    text-align: right;
  }
}

.techniqueTypesListItem {
  display: inline-block;
  margin: 0 5px 5px 0;
  padding: 0 12px;
  border-radius: 6px;
  line-height: 37px;
  font-weight: var(--fontWeightSemiBold);
  font-family: var(--buttonFontFamily);
}

.languages {
  padding: 0;
  margin: 0 0 10px;
}

.languagesItems {
  text-transform: capitalize;
  font-weight: var(--fontWeightRegular);
}

.bio {
  margin: 8px 0 0;
  padding: 0;
  font-weight: var(--fontWeightLight);
  max-width: 742px;
}

.titleHolder {
  margin: 40px 0 20px;
  
  @media (--viewportMedium) {
    display: flex;
    align-items: flex-start;
  }
  
  @media (--viewportLarge) {
    margin: 44px 0 48px;
  }

  & .heading {
    padding: 0;
    margin: 0 0 20px 0;
    word-break: break-word;
    
    @media (--viewportMedium) {
      font-size: 48px;
      margin: 0 34px 0 0;
    }

    @media (--viewportLarge) {
      font-size: 64px;
    }
  }

  & .price {
    padding: 0;
    margin: 0;
    font-size: 24px;
    line-height: 1;
    font-weight: var(--fontWeightLight);

    @media (--viewportMedium) {
      font-size: 48px;
    }
    
    @media (--viewportLarge) {
      font-size: 64px;
    }
  }
}

.headingInfoHolder {
  
  @media (--viewportLarge) {
    display: flex;
    align-items: center;
  }

  & .offerDurationLabel {
    font-size: 18px;
    font-weight: var(--fontWeightBold);
    
    @media (--viewportLarge) {
      font-size: 28px;
    }
  }

  & .offerDuration {
    margin: 0 0 22px;
    padding: 0;
    font-size: 16px;
    font-weight: var(--fontWeightLight);
    
    @media (--viewportLarge) {
      font-size: 24px;
      margin: 0 22px 0 0;
    }
  }

  & .offerLocation {
    display: inline-block;
    padding: 4px 12px;
    font-size: 16px;
    line-height: 1.2;
    font-weight: var(--fontWeightSemiBold);
    border-radius: 8px;

    @media (--viewportLarge) {
      font-size: 22px;
      line-height: 34px;
    }
  }

  & .offerLocationInPerson {
    background-color: #DDFFED;
  }
  
  & .offerLocationOnline {
    background-color: #CBEFF7;
  }

  & .offerLocationClientLocation {
    background-color: #F7E4C8;
  }
}
