.hiddenFileInput {
    position: fixed;
    visibility: hidden;
}

.wrapper {
    display: flex;
    justify-content: start;
    align-items: center;
}

.addFileContainer {
    /* position: absolute; */
    display: flex;
    flex-direction: column;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 0;

    /* Colors */
    background-color: var(--colorWhite);

    /* Behaviour */
    cursor: pointer;
    transition: var(--transitionStyleButton);

    & img {
      width: 24px;
      height: 24px;
    }
}

.addFileContainerRounded {
    composes: addFileContainer;
    border-radius: 50%;
}

.image {
    width: 150px;
    height: 150px;
    object-fit: cover;
}

.imageRounded {
    composes: image;
    border-radius: 50%;
}

.removeImage {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 33px;
    height: 33px;
    padding: 0;
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    border-radius: 50%;
    cursor: pointer;

    & svg {
        position: absolute;
        top: 12px;
        left: 12px;
        width: 10px;
        height: 10px;
        fill: var(--colorGrey300);
        stroke: var(--colorGrey300);
    }

    &:hover svg {
        fill: var(--colorWhite);
        stroke: var(--colorWhite);
    }
}

.tipContainer {
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-radius: var(--borderRadiusMedium);
  border: 1px solid var(--colorGrey300);
  background-color: #FBFFEE;
  gap: 12px;
  font-size: 14px;

  .tipHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    >span {
      display: flex;
      flex-direction: row;
      gap: 12px;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .closeIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 18px;
    width: 18px;
    cursor: pointer;
  }
}

.error {
  color: var(--colorFail);
  font-size: 10px;
}
